import { http } from "@/http/axios.js";
// 变更收货地址
export function updateOrderAddressAPI(data) {
  return http({
    url: "/admin/order/edit/address",
    method: "post",
    data,
  });
}
// 变更订单商品
export function updateOrderGoodsAPI(data) {
  return http({
    url: "/admin/order/edit/goods",
    method: "post",
    data,
  });
}
//门店结算金额保存
export function calcStoreSaveApi(data) {
  return http({
    url: "/admin/order/store/settle/save",
    method: "post",
    data,
  });
}
//门店待结算金额明细
export function settlementAmountDetails(params) {
  return http({ url: "/admin/order/store/settle/info", method: "get", params });
}
// 商品列表
export function getGoodsListAPI(params) {
  return http({
    url: "/admin/goods/page",
    method: "get",
    params,
  });
}
// 商品详情
export function getGoodsDetailAPI(id) {
  return http({
    url: "/admin/goods/detail",
    method: "get",
    params: { id },
  });
}
// 分配客服
export function getKfOrderDrawAPI(data) {
  return http({
    url: "/admin/kf/order/allocate",
    method: "post",
    data,
  });
}
// 客服列表
export function getUserListAPI(params = {}) {
  return http({
    url: "/admin/user/list",
    method: "get",
    params,
  });
}
// 审核同意
export function handleReviewPassAPI(data) {
  return http({
    url: "/admin/review/order/pass",
    method: "post",
    data,
  });
}
// 审核取消
export function handleReviewCancelAPI(data) {
  return http({
    url: "/admin/review/order/cancel",
    method: "post",
    data,
  });
}
// 审核拒绝
export function handleReviewRefuseAPI(data) {
  return http({
    url: "/admin/review/order/refuse",
    method: "post",
    data,
  });
}
// 备注列表
export function getRemarkListAPI(params) {
  return http({
    url: "/admin/order/trace/page",
    method: "get",
    params,
  });
}
// 添加备注
export function handleAddRemarkAPI(data) {
  return http({
    url: "/admin/order/trace/add",
    method: "post",
    data,
  });
}
// 用户评分
export function handleMemberGradeAPI(data) {
  return http({
    url: "/admin/member/grade",
    method: "post",
    data,
  });
}
// 紧急联系人列表
export function getRelationListAPI(memberId) {
  return http({
    url: "/admin/member/relation/list",
    method: "get",
    params: { memberId },
  });
}
// 添加紧急联系人
export function addRelationAPI(data) {
  return http({
    url: "/admin/member/relation/add",
    method: "post",
    data,
  });
}
// 编辑紧急联系人
export function editRelationAPI(data) {
  return http({
    url: "/admin/member/relation/edit",
    method: "post",
    data,
  });
}
// 校验紧急联系人
export function riskRelationAPI(data) {
  return http({
    url: "/admin/member/relation/risk/alert",
    method: "post",
    data,
  });
}
// 紧急联系人详情
export function getRelationDetailAPI(id) {
  return http({
    url: "/admin/member/relation/detail",
    method: "get",
    params: { id },
  });
}
// 删除紧急联系人
export function delRelationAPI(id) {
  return http({
    url: "/admin/member/relation/delete",
    method: "post",
    data: { id },
  });
}
// 保存工作单位
export function saveMemberProfileAPI(data) {
  return http({
    url: "/admin/member/profile/save",
    method: "post",
    data,
  });
}
// 合同列表
export function getContactListAPI(params) {
  return http({
    url: "/admin/review/contract/list",
    method: "get",
    params,
  });
}
// 修改服务费率
export function updateServicePriceRateAPI(data) {
  return http({
    url: "/admin/review/order/servicePriceRate",
    method: "post",
    data,
  });
}
// 修改租期
export function updatePeriodNumAPI(data) {
  return http({
    url: "/admin/review/order/billPeriodNum",
    method: "post",
    data,
  });
}
// 修改零售价
export function updatePriceAPI(data) {
  return http({
    url: "/admin/order/salePrice/edit",
    method: "post",
    data,
  });
}
// 门店套餐列表
export function getStoreComboListAPI(params) {
  return http({
    url: "/admin/store/combo/gradeList",
    method: "get",
    params,
  });
}
// 修改门店订单套餐
export function updateStoreComboAPI(data) {
  return http({
    url: "/admin/order/store/combo/edit",
    method: "post",
    data,
  });
}
// 修改第一期金额
export function updateStoreFirstPriceAPI(data) {
  return http({
    url: "/admin/order/store/bill/option/calc",
    method: "post",
    data,
  });
}
// 评分明细列表
export function getScoreBillInfoAPI(params) {
  return http({
    url: "/admin/order/score/bill/list",
    method: "get",
    params,
  });
}
// 保存订单评分资料
export function hitOrderScoreProfileAPI(data) {
  return http({
    url: "/admin/order/score/profile/hit",
    method: "post",
    data,
  });
}
// 修改订单最终评级
export function updateFinalHitGradeAPI(data) {
  return http({
    url: "/admin/order/score/grade/edit",
    method: "post",
    data,
  });
}
// 门店待结算金额计算
export function calcStoreSettleAPI(data) {
  return http({
    url: "/admin/order/store/settle/calc",
    method: "post",
    data,
  });
}

// 修改门店方案列表
export function getStoreComboList(params) {
  return http({
    url: "/admin/order/store/combo/list",
    method: "get",
    params,
  });
}

// 修改门店方案
export function editStoreComboList(data) {
  return http({
    url: "/admin/order/store/combo/edit",
    method: "post",
    data,
  });
}
