import { render, staticRenderFns } from "./select-combo-update-goods.vue?vue&type=template&id=67f0d685&scoped=true"
import script from "./select-combo-update-goods.vue?vue&type=script&lang=js"
export * from "./select-combo-update-goods.vue?vue&type=script&lang=js"
import style0 from "./select-combo-update-goods.vue?vue&type=style&index=0&id=67f0d685&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f0d685",
  null
  
)

export default component.exports